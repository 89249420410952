import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./PostList.scss";
import RenderContent from "../../RenderContent";
import Pagination from "../../Pagination";
import { Image } from "../../Image";
import PostListItem from "../PostListItem";
import { PostListNavigation } from "../PostListNavigation";

const PostList = (props) => {
  const {
    posts,
    title,
    pageContext,
    categoryFilter,
    pathPrefix,
    siteMetadata,
    showAuthor,
    dateFormat,
  } = props;

  return (
    <section className="post-list">
      <div className="post-list-wrap">
        <div className="list">
          {posts &&
            posts.map(
              (post, index) =>
                post.node.slug !== "gatsby-demo" && (
                  <PostListItem
                    key={index}
                    data={post.node}
                    dateFormat={dateFormat}
                    siteMetadata={siteMetadata}
                    pathPrefix={pathPrefix}
                    showAuthor={showAuthor}
                  />
                )
            )}
        </div>
        <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
      </div>
    </section>
  );
};

export default PostList;

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
      path
    }
    title
    excerpt
    date
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
