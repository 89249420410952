import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageHeader } from "../components/PageHeader";
import PostList from "../components/Blog/PostList";
import { decodeEntities } from "../utils/helpers";

const Blog = (props) => {
  const { data, pageContext, location } = props;

  const {
    wordpressPost: page = [],
    allWordpressPost,
    wordpressPage,
    categoryFilter,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { title, yoast, acf } = page
    ? page
    : { title: null, yoast: {}, acf: {} };
  const { showAuthor, customCss } = siteSettings.options;
  const { wordpressUrl } = wordpressWpSettings;
  const { title: siteTitle, date_format } = wordpressWpSettings;
  const { edges: posts } = allWordpressPost;
  const { featureImage: featuredImage } = wordpressPage?.acf;
  const breadcrumbTitle = wordpressPage?.acf?.title;

  return (
    <>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Blog | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <PageHeader
        headerTitle="News and Media"
        location={location}
        headerBackgroundImage={featuredImage}
        breadcrumbTitle={breadcrumbTitle}
      />
      <PostList
        posts={posts}
        title="Latest posts"
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix={"/blog/"}
        showAuthor={showAuthor}
        dateFormat={date_format}
      />
    </>
  );
};

export default Blog;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "blog" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    wordpressPage: wordpressPage(path: { eq: "/news-and-media/" }) {
      title
      acf {
        title
        featureImage {
          source_url
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    wordpressCategory(slug: { eq: "uncategorised" }) {
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
